import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled,{keyframes} from 'styled-components'

////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
    width: ${p=>p.width};
    margin: ${p=>p.margin};
`;

const Button = styled.button`
    border          : none;
    outline         : none;
    padding         : ${p=>p.mLoading?'0':'12px 16px'};
    border-radius   : 5px;
    display         : flex;
    align-items     : center;
    width           : ${p=>p.width};
    font-size       : ${p=>p.fontsize};
    font-weight     : bold;
    justify-content : center;
    cursor          : ${p=>p.type === 'disable'?'':'pointer'};
    background      : ${p=>
            p.type === 'primary'? 'rgb(237,28,36)':
            p.type === 'secondary'? 'rgb(220,220,220)':'' };
    color           : ${p=>
        p.type==='secondary'?'black':
        p.type==='disable'?'rgb(153,153,153)':'white'};

    &:hover{
        background : ${p=>
                p.type === 'primary'? 'rgba(220,0,8,1)':
                p.type === 'secondary'? 'darkgrey':''
            };
    }
`;

const mRotate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);}
`

const Loading = styled.div`
    display: inline-block;
    margin: 4.5px;

    &:after{
        content: " ";
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 4px solid #fff;
        border-color: #fff transparent #fff transparent;
        animation: ${mRotate} 1.2s linear infinite;
    }

    &:hover{
        background: ${p=>
                p.type === 'primary'? 'rgba(220,0,8,1)':
                p.type === 'secondary'? 'darkgrey':''
            };
    }
`;

////////////////////////////////////////////////////////////////////////////////

const Component = ({label='Button',width='200px',mtype='primary',onClick=()=>{},fontsize='14px',margin='16px',mLoading=false}) => {
    return <Container width={width} margin={margin}>
        <Button
        type={mtype}
        width={width}
        onClick={()=>{
            if(mtype !=='disable' && !mLoading)onClick()
        }}
        fontsize={fontsize}
        mLoading={mLoading}>
            {mLoading?<Loading />:label}
        </Button>
    </Container>
}
export default Component;
