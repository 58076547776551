import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {bookAppointment} from './../../data/api'
import {setAppointment} from './../../data/actions'
import styled from 'styled-components'

import {Container,Divider,Text,Image,ImageIcon,Dialog,Loading} from './../../componentsV2/style'
import Button from './../../components/button'
import Input from './../../components/input'
import ReCAPTCHA from "react-google-recaptcha";
import QRCode from "react-qr-code";

////////////////////////////////////////////////////////////////////////////////

const Content = styled.div`
    width: ${p=>p.width};
    flex: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

////////////////////////////////////////////////////////////////////////////////

const Component = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const navigate = useHistory();
    const dispatch = useDispatch();
    const lang     = useSelector(state=>state.data.language);
    const language = useSelector(state=>state.config.language[state.data.language??'en']);
    const appointment = useSelector(state=>state.data.appointment ?? {})
    useEffect(()=>{ if(Object.keys(appointment).length === 0) navigate.push('/?lang='+(lang===undefined??'en':lang))},[appointment])

    const readServiceName = (raw) =>{
        try {
            const parsed = JSON.parse(raw)
            return parsed.servnm;
        } catch (e) {
            return ''
        }
    }

    return <Container flex={1} vertical align='center'>
        <Container centervertical maxwidth={isMobile?'400px':'800px'} animate  overflow={isMobile?'auto':'hidden'}>
            <Image src='./success.png' style={{width:70,height:70,margin:24}} />
            <Text Title>{language.apmt_success}</Text>
            <Text subtitle align='center'>{language.apmt_sendto} {appointment.email??''}</Text>
            <Container card={!isMobile} width='stretch' justify='space-between' padding='0' margin='36px 0 16px' horizontal wrap={isMobile?'wrap':'nowrap'} overflow='hidden'>
                <Container alignself='stretch' card={isMobile} overflow='hidden' shrink={1}>
                    <Container padding='30px' center width='stretch'>
                        <Text fontsize='20px' weight='bold'>{appointment.apmt_code??''}</Text>
                        <Text subtitle>{language.apmt_code}</Text>
                    </Container>
                    <Divider/>
                    <Container padding='30px' center width='stretch' flex={1}>
                        <Container padding='16px'> <QRCode width='134px' value={appointment.apmt_code??'Loading...'} size={134}/> </Container>
                        <Text subtitle align='center'>{language.apmt_scan}</Text>
                    </Container>
                </Container>
                <Divider vertical/>
                <Container vertical='flex-start' alignself='stretch' card={isMobile} overflow='hidden' margin={isMobile?'16px 0 0':'0'} shrink={1}>
                    <Container padding='16px' width='stretch' align='flex-start'>
                        <Text weight='bold'>{language.apmt_date}</Text>
                        <Text subtitle margin='8px 0 16px'>{appointment.apmt_date??''}</Text>

                        <Text weight='bold'>{language.apmt_time}</Text>
                        <Text subtitle margin='8px 0 16px'>{appointment.apmt_time??''}</Text>

                        <Text weight='bold'>{language.apmt_addr}</Text>
                        <Text subtitle margin='8px 0 16px'>{appointment.branch_address??''}</Text>

                        <Text weight='bold'>{language.apmt_branch}</Text>
                        <Text subtitle margin='8px 0 16px'>{appointment.branch_name??''}</Text>

                        <Text weight='bold'>{language.apmt_serv}</Text>
                        <Text subtitle margin='8px 0 16px'>{readServiceName(appointment.services)}</Text>
                    </Container>
                    <Divider />
                    <Container padding='16px'>
                        <Text subtitle>{language.apmt_notes}</Text>
                    </Container>
                </Container>
            </Container>
            <Text subtitle>{language.apmt_notes2}</Text>
        </Container>
    </Container>
}
export default Component;
