import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled,{keyframes} from 'styled-components'
import { Icon, InlineIcon } from '@iconify/react';
import magnifyIcon from '@iconify/icons-mdi/magnify';

////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
    width: ${p=>p.width};
    height: 40px;
    border: 1px solid  rgba(102,102,102,0.2);
    margin: ${p=>p.margin};
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center
`;

const Input = styled.input`
    border: none;
    outline: none;
    padding: 14px 16px;
    flex    :1;


    ::-webkit-input-placeholder {
        font-size: 12px;
    }
`;
const Button = styled.div`
    height: 30px;
    background: red;
    color           : white;
    border-radius   : 4px;
    display         : flex;
    align-items     : center;
    padding         : 16px;
    margin          : 2px;
    justify-content : center;
    cursor          : pointer;
`;

////////////////////////////////////////////////////////////////////////////////

const Component = ({margin='',hint='Search...',width='300px',label,onSearchClick = ()=>{}}) => {
    const [value,setValue] = useState('');

    return <Container margin={margin} width={width}>
        <Icon icon={magnifyIcon} style={{margin:'0 0 0 8px'}}/>
        <Input type='text' placeholder={hint} value={value} onChange={e=>setValue(e.target.value)}
        onKeyDown={e=>{
            if(e.key === 'Enter') {
                onSearchClick(value)
            }
        }}/>
        <Button onClick={()=>onSearchClick(value)}>{label}</Button>
    </Container>
}
export default Component;
