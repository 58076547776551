import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled,{keyframes} from 'styled-components'
import { Icon, InlineIcon } from '@iconify/react';
import menuDown from '@iconify/icons-mdi/menu-down';

////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
    width       : ${p=>p.isMobile?'stretch':''};
    position    : relative;
    display     : flex;
    outline     : none;
    flex-wrap   : wrap;
    margin      : ${p=>p.margin};
    flex-shrink     : 0;
`;
//min-width       : 200px;
const Head = styled.div`
    width           : ${p=>p.isMobile?'100%':''};
    min-width       : ${p=>p.minwidth};
    height          : 40px;
    border          : 1px solid rgba(102,102,102,0.2);
    display         : flex;
    align-items     : center;
    padding         : 16px;
    border-radius   : 4px;
    margin          : ${p=>p.margin};
    cursor          : pointer;
`;
const Text = styled.div`
    flex: 1;
`;
const Body = styled.div`
    top         : ${p=>
            p.isMobile?p.left?'96px':'40px':
            p.left?'40px':'40px'};
    left        : ${p=>
            p.isMobile? p.left? '0px':'0px':
            p.left?'186px':'0px'};
    display     : ${p=>p.show?'block':'none'};
    width       : ${p=>p.width};
    min-width   : ${p=>p.minwidth};
    max-height  : 400px;
    position    : absolute;
    margin      : 12px 0 0 ;
    border      : 1px solid rgba(102,102,102,0.2);
    z-index     : 99;
    background  : white;
    overflow-y  : auto;
    overflow-x  : hidden;
    border-radius: 4px;
    cursor      : pointer;
`;
const BodyItem = styled.div`
    padding     : 16px;
    white-space : nowrap;
    user-select : none;

    &:hover{
        background: rgba(0,0,0,0.2);
    }
`;
////////////////////////////////////////////////////////////////////////////////

const Component = ({
    items=[],
    itemlabel,
    childlabel,
    itemkey='cityname',
    itemchild='districts',
    childkey='district_name',
    enableSelectAll=true,
    margin,
    isMobile=false,
    onItemClick=()=>{},
    onChildClick=()=>{}
}) => {
    const [show,toggle]             = useState(false)
    const [selected,setSelected]    = useState({})
    const [showChild,toggleChild]   = useState(false)
    const [selectedChild,setSelectedChild] = useState({})

    const [allChild,setAllChild]    = useState([]);

    useEffect(()=>{
        if(items.length>0){
            setSelected(enableSelectAll?{[itemkey]:itemlabel}:items[0])

            let temp = [];
            items.map((item,i)=>{ temp = [...temp,...item[itemchild]]; })
            setAllChild(temp)
        }
    },[items])

    useEffect(()=>{
        if(selected[itemchild] && selected[itemchild].length>0)
        setSelectedChild(enableSelectAll?{[childkey]:childlabel}:selected[itemchild][0])
        onItemClick(selected)
    },[selected]);

    useEffect(()=>{
        onChildClick(selectedChild)
    },[selectedChild])

    return <Container isMobile={isMobile} tabIndex="0" onBlur={()=>{toggle(false); toggleChild(false)}} wrap='wrap' >
        <Head
        isMobile={isMobile}
        minwidth={isMobile?(selected[itemchild] && selected[itemchild].length>0)?'174px':'':'170px'}
        margin={isMobile?'8px 0':'8px 16px 8px 0'}
        onClick={()=>{
            toggle(!show)
            toggleChild(false);
        }}>
            <Text>{selected[itemkey]??itemlabel}</Text>
            <InlineIcon icon={menuDown}/>
        </Head>
        <Body
            isMobile={isMobile} show={show} left={false}
            width={isMobile?'100%':''}
            minwidth={isMobile?(selected[itemchild] && selected[itemchild].length>0)?'174px':'':'170px'}
            >
            {
                !enableSelectAll?'':
                <BodyItem onClick={()=>{
                    toggle(false);
                    setSelected({[itemkey]:itemlabel})
                }}>{itemlabel}</BodyItem>
            }
            {
                items.length === 0?'':
                items.map((item,i)=><BodyItem key={i}
                onClick={()=>{
                    toggle(false);
                    setSelected(item)}
                }>{item[itemkey]}</BodyItem>)
            }
        </Body>
        {
            //selected[itemchild] && selected[itemchild].length>0?
            <>
                <Head
                isMobile={isMobile}
                minwidth={isMobile?'':'170px'}
                margin={'8px 0px 8px 0'}
                onClick={()=>{
                    toggle(false)
                    toggleChild(!showChild)
                }}>
                    <Text>{selectedChild[childkey]??childlabel}</Text>
                    <InlineIcon icon={menuDown}/>
                </Head>
                <Body
                    isMobile={isMobile}
                    show={showChild}
                    left={true}
                    width={isMobile?'100%':''}
                    minwidth={isMobile?'174px':'170px'}>
                {
                    !enableSelectAll?'':
                    <BodyItem onClick={()=>{
                        toggleChild(false);
                        setSelectedChild({[childkey]:childlabel})
                    }}>{childlabel}</BodyItem>
                }
                {
                    selected[itemchild] && selected[itemchild].length>0?
                    selected[itemchild].map((item,i)=><BodyItem key={i}
                    onClick={()=>{
                        toggleChild(false);
                        setSelectedChild(item)
                    }}> {item[childkey]} </BodyItem>)
                    :''

                }
                </Body>
            </>
            //:''
        }

    </Container>
}

// allChild.map((item,i)=><BodyItem key={i}
// onClick={()=>{
//     toggleChild(false);
//     setSelectedChild(item)
// }}>{item[childkey]}</BodyItem>)


export default Component;
