// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'core-js'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

import { polyfillLoader } from 'polyfill-io-feature-detection';
// This function load polyfills only if needed. By default it uses polyfill.io
polyfillLoader({
  "features": "Promise,fetch",
  "onCompleted": main
})
function main() {
  ReactDOM.render(
    <App />
    , document.getElementById('root')
  );
}





// ReactDOM.render(
//    <App />,
//    document.getElementById('root')
// );

//
