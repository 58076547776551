import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {bookAppointment,validateV3} from './../../data/api'
import {setAppointment} from './../../data/actions'
import styled from 'styled-components'

import {GoogleReCaptchaProvider,GoogleReCaptcha} from 'react-google-recaptcha-v3';
import {Container,Center,Text,Card,Placeholder,InputText,Divider} from './../../componentsV2/style'
import Button from './../../componentsV2/button'
import Input from './../../components/input'
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

////////////////////////////////////////////////////////////////////////////////

const Content = styled.div`
    width           : ${p=>p.width};
    flex            : 1;
    padding         : 16px;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
`;
const Circle = styled.div`
    width           : 32px;
    height          : 32px;
    background      : rgb(237,28,36);
    border-radius   : 50%;
    line-height     : 32px;
    text-align      : center;
    font-size       : 16px;
    font-weight     : bold;
    color           : white;
    margin          : 0 16px 0 0;
`;

////////////////////////////////////////////////////////////////////////////////

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const validatePhone = (phone) =>{
    return phone.length === 10;
}

////////////////////////////////////////////////////////////////////////////////

const Component = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' })
    const navigate = useHistory();
    const dispatch = useDispatch();
    const config   = useSelector(state=>state.config);
    const lang     = useSelector(state=>state.data.language);
    const language = useSelector(state=>state.config.language[state.data.language??'en']);
    const [open,toggle]             = useState(false);
    const [errorTitle,setErrorTitle]= useState('Error');
    const [errorMsg,setErrorMsg]    = useState('');
    const [errorCode,setErrorCode]  = useState('');
    const [loading,setLoading]      = useState(false);

    const selectedCust     = useSelector(state=>state.select.customer_type);
    const selectedBranch   = useSelector(state=>state.select.branch)
    const selectedTimeslot = useSelector(state=>state.select.timeslot)
    const selectedService  = useSelector(state=>state.select.service)
    useEffect(()=>{ if(selectedService === undefined) navigate.push('/?lang='+(lang===undefined?'en':lang))},[selectedService])

    const [formdata,setformdata] = useState({name:'',email:'',phone:'',account:''});
    const [captchakey,setCaptchakey] = useState('');
    const [captchaSolved,setSolved] = useState(false);
    const [error,setError] = useState(false);

    useEffect(()=>{
        setError({
            name: formdata.name         === ''?'Cannot be empty':'',
            // email: formdata.email       === ''?'Cannot be empty':'',
            email: validateEmail(formdata.email)?'':'error',
            //phone: formdata.phone       === ''?'Cannot be empty':'',
            phone: validatePhone(formdata.phone)?'':'Cannot be empty',
            account: formdata.account   === ''?'Cannot be empty':''
        })
    },[formdata])

    const checkIfFormValid = () => {
        return selectedCust === '11' ?
        captchaSolved && error.email === '' && error.phone === '' :                            // retail
        captchaSolved && error.email === '' && error.phone === '' && error.account === '';     // corperate
    }

    const toFormData =(obj) =>{
        let formData = new FormData();
        Object.keys(obj).map(key=>formData.append(key,obj[key]));
        return formData;
    }

    const validateRecaptchaV3 = async (token) =>{
        //console.log(token);
        const response = await validateV3(config.v3s,token);
        setSolved(response.success)
        // console.log(response);
    }

    const onNextClick = async () =>{
        //config,services,branchid,deptid,date,time,custid,custname,custphone,custemail
        setLoading(true);
        const response = await bookAppointment(
            lang,
            config,
            selectedCust,
            JSON.stringify({
                    ...selectedService,
                    ...{required:selectedService && selectedService.required?
                        selectedService.required.map(item=>{return {label:item.label,value:item.value}}):
                        undefined
                    }
                }),
            selectedBranch.branchid,
            1,
            selectedTimeslot.date,
            selectedTimeslot.time,
            selectedCust === '11'? '4':'7', //custidtype    coperate=7 normal11
            '99999999', //hardcoded 999
            formdata.name,
            formdata.phone,
            formdata.email,
            formdata.account,
            captchakey,
        );
        setLoading(false)
        console.log(response);
        if(response.error || response.error_code){
            switch (response.status) {
                case 404: setErrorTitle(language.networkerror)
                    break;
                case 500: setErrorTitle(language.error)
                    break;
                default: setErrorTitle(language.cantbook);
            }
            setErrorCode(response.error_code)
            setErrorMsg(response.error)
            switch (response.error_code) {
                case "206"  :
                    setErrorTitle(language.title_206);
                    setErrorMsg(language.error_206); break; // mobile number format is wrong
                case "207"  :
                    setErrorTitle(language.title_207);
                    setErrorMsg(language.error_207); break; // email wrong
                case "208"  :
                    setErrorTitle(language.title_208);
                    setErrorMsg(language.error_208); break; // duplicate
                case "209"  :
                    setErrorTitle(language.title_209);
                    setErrorMsg(language.error_209); break; // cant book on this date
                case "210"  :
                    setErrorTitle(language.title_210);
                    setErrorMsg(language.error_210); break; // cant book on this date
                case "211"  :
                    setErrorTitle(language.title_211);
                    setErrorMsg(language.error_211); break; // booking slot full
                case "301"  :
                    setErrorTitle(language.title_301);
                    setErrorMsg(language.error_301); break;
                case "302"  :
                    setErrorTitle(language.title_302);
                    setErrorMsg(language.error_302); break;
                case "303"  :
                    setErrorTitle(language.title_303);
                    setErrorMsg(language.error_303); break;
                case "304"  :
                    setErrorTitle(language.title_304);
                    setErrorMsg(language.error_304); break;
                case "305"  :
                    setErrorTitle(language.title_305);
                    setErrorMsg(language.error_305); break;
                case "306"  :
                    setErrorTitle(language.title_306);
                    setErrorMsg(language.error_306); break;
                default:
                    setErrorTitle(language.cantbook);
                    setErrorMsg(response.error);

            }
            toggle(true);
        }else{
            dispatch(setAppointment(response))
            navigate.push('/appointment?lang='+lang)
        }
    }

    return <Container flex={1} vertical>
        <Container flex={1} vertical maxwidth={isMobile?'400px':'800px'} animate>
            <Text Title animate>{language.form_title}</Text>
            <Text subtitle animate>{language.form_sub}</Text>
            <Container show={selectedCust==='12'?'flex':'none'} animate align='flex-start'>
                <Container margin='16px 0' horizontal align='center'>
                    <Circle>1</Circle>
                    <Text fontsize='18px' weight='bold'>{language.form_company}</Text>
                </Container>
                <Input
                    label={language.form_tax}
                    redlabel={'*'}
                    mtype='number'
                    hint={language.form_tax}
                    width={isMobile?'100%':'50%'}
                    padding='0 16px 0 0'
                    value={formdata.account}
                    maxLength={14}
                    onChange={e=>setformdata({...formdata,...{account:e}})}
                />
                <Container margin='16px 0 0' horizontal align='center'>
                    <Circle>2</Circle>
                    <Text fontsize='18px' weight='bold'>{language.form_user }</Text>
                </Container>
            </Container>

            <Container horizontal wrap='wrap' flex='unset' vertical='flex-start' animate>
                <Input
                    label={language.form_name}
                    hint={language.form_name}
                    mtype='name'
                    width={isMobile?'100%':'50%'}
                    padding={isMobile?"16px 0 0":"16px 16px 0 0"}
                    value={formdata.name}
                    onChange={e=>setformdata({...formdata,...{name:e}})}/>
                <Input
                    label={language.form_email}
                    redlabel={'*'}
                    mtype='email'
                    isValid={valid=>{
                        setError({...error,...{email:valid?'':language.error_207}})
                    }}
                    invalidString={language.error_207}
                    hint={language.form_email}
                    width={isMobile?'100%':'50%'}
                    padding={isMobile?"16px 0 0":"16px 16px 0 0"}
                    value={formdata.email}
                    onChange={e=>setformdata({...formdata,...{email:e}})}/>
                <Input
                    label={language.form_phone}
                    redlabel={'*'}
                    mtype='tel'
                    isValid={valid=>{
                        setError({...error,...{phone:valid?'':language.error_206}})
                    }}
                    invalidString={language.error_206}
                    hint={language.form_phone}
                    width={isMobile?'100%':'50%'}
                    padding={isMobile?"16px 0 0":"16px 16px 0 0"}
                    value={formdata.phone}
                    maxLength={10}
                    onChange={e=>setformdata({...formdata,...{phone:e}})}/>
            </Container>


            {
                config.recaptcha === 'v2'?
                <Container padding='16px' animate>
                    <ReCAPTCHA
                        style={{selfAlign:'center'}}
                        sitekey={config.v2}
                        onChange={(val)=>{
                            setCaptchakey(val)
                            setSolved(val!==null)
                        }}
                    />
                </Container>:''
            }


            <Container horizontal justify='space-between' margin='16px 0' wrap='wrap' animate>
                <Button mtype='secondary' label={language.back} margin='8px 0' onClick={()=>window.history.back()} width={isMobile?'100%':'200px'}/>
                <Button mtype={checkIfFormValid()?'primary':'disable'} label={language.next} margin='8px 0' mLoading={loading} onClick={onNextClick} width={isMobile?'100%':'200px'}/>
            </Container>


            <div style={{position:'absolute',bottom:0,margin:'16px',background:'rgba(0,0,0,0.02)',fontSize:12}}>
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>

        </Container>

        {
            config.recaptcha === 'v3'?
            <GoogleReCaptchaProvider reCaptchaKey={config.v3}>
                <GoogleReCaptcha onVerify={async (token)=>{
                    const response = await validateV3(config.v3s,token);
                    setSolved(response.success);
                }} />
            </GoogleReCaptchaProvider>:''
        }



        <div
        style={{position:'fixed',background:'rgba(0,0,0,0.2)',width:'100%',height:'100%',display:open?'flex':'none',alignItems:'center',justifyContent:'center'}}>
            <Container width='320px' card>
                <img src='./appointment.png' width='190px' style={{margin:16}}/>
                <Text align='center' margin='0 16px' fontsize='18px' weight='bold'>{errorTitle}</Text>
                <Text align='center' margin='16px 16px 8px'>{errorMsg}</Text>
                <Button mtype='primary' width='288px' label={language.close} onClick={()=>{
                    if(errorCode!=undefined && (errorCode === '211' || errorCode === '208')) navigate.push('/branches?lang='+lang)
                    else if(errorCode!=undefined &&(errorCode === '209' || errorCode === '210')) navigate.push('/?lang='+lang)
                    else toggle(false)
                }}/>
            </Container>
        </div>

        {
        // <Snackbar
        //     open={open}
        //     autoHideDuration={6000}
        //     onClose={(e,reason)=>{toggle(false)}}
        //     message={errorMsg}
        //     action={<IconButton size="small" aria-label="close" color="inherit" onClick={(e,reason)=>{toggle(false)}}>x</IconButton>} />
        }
    </Container>
}
export default Component;
