import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled,{keyframes} from 'styled-components'

////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
    margin: ${p=>p.margin};
`;

const Button = styled.button`
    border: none;
    outline: none;
    padding: 12px 16px;
    border-radius: 5px;
    background: ${p=>
            p.type === 'primary'? 'rgb(237,28,36)':
            p.type === 'secondary'? 'rgb(220,220,220)':''
        };
    color: ${p=>
        p.type==='secondary'?'black':
        p.type==='disable'?'black':'white'};
    width: 200px;
    font-size: ${p=>p.fontsize};
    font-weight: bold;

    &:hover{
        background: ${p=>
                p.type === 'primary'? 'rgba(220,0,8,1)':
                p.type === 'secondary'? 'darkgrey':''
            };
    }
`;
////////////////////////////////////////////////////////////////////////////////

const Component = ({label='Button',mtype='primary',onClick=()=>{},fontsize='14px',margin='16px'}) => {
    return <Container margin={margin}>
        <Button type={mtype} onClick={()=>{if(mtype !=='disable')onClick()}} fontsize={fontsize}>{label}</Button>
    </Container>
}
export default Component;
