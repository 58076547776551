import React,{useState,useEffect} from 'react';
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {selectService} from './../../data/actions'
import styled from 'styled-components'

import {Container,Divider,Text,Image,InputContainer,Input} from './../../componentsV2/style'
import Button from './../../componentsV2/button'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

////////////////////////////////////////////////////////////////////////////////

const Component = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const config   = useSelector(state=>state.config)
    const lang     = useSelector(state=>state.data.language);
    const language = useSelector(state=>state.config.language[state.data.language??'en']);
    const [loading,setloading]  = useState({personal:false,coperate:false})
    const [open,toggle]         = useState(false);
    const [errorMsg,setErrorMsg]= useState('')
    const [page,setPage] = useState('main');

    const services = useSelector(state=>state.data.services);
    useEffect(()=>{if(services === undefined) navigate.push('/?lang='+(lang===undefined?'en':lang))},[services])

    const [selected,setSelected] = useState({});

    const ServiceListing = ({main}) => {

        const [shouldAnimate,setShouldAnimate] = useState(true)
        const [enable,toggle] = useState(false);
        const [selectedSub,setSelectedSub] = useState();

        useEffect(()=>{
            if(selectedSub !== undefined && selectedSub.servid) toggle(true)
        },[selectedSub])

        const onItemClick = service => {
            console.log('service->');
            console.log(service);
            setSelected(service)
            dispatch(selectService(service))

            const gotSubservice = service.subservice && service.subservice.length >0;
            const gotRequiment  = service.required && service.required.length >0;
            if(!gotSubservice && !gotRequiment){ navigate.push('/branches?lang='+lang)}
        }

        const ServiceItem = ({item}) => <Container card horizontal align='center' hoverable padding={main?'16px':'24px 16px'} margin='16px 0 0' animate onClick={()=>onItemClick(item)}>
            <Image src='./cash_icon.png' width='36px' height='36px' margin='0 16px 0 0'/>
            <Text >{item.servnm}</Text>
        </Container>

        const SubServiceItem = ({item}) => <Container card horizontal align='center' hoverable padding={main?'16px':'24px 16px'} margin='16px 0 0' animate={shouldAnimate}
            selected={selectedSub !== undefined && (item.servid === selectedSub.servid)}
            onClick={()=>{ setShouldAnimate(false); setSelectedSub(item);}}>
            <Text >{item.servnm}</Text>
        </Container>

        return <>
            <Text Title align='left' alignself='flex-start' animate>{main? language.service_title: language.service_title2} </Text>
            <Text subtitle align='left' alignself='flex-start' animate>{main? language.service_sub:language.service_sub2}</Text>
            {
                services === undefined? '':
                main?
                    services.length > 0?
                        services.map((item,i)=><ServiceItem key={i} item={item} />)
                        : <Container margin='16px 0' align='flex-start' animate>Empty</Container> :
                    selected.subservice && selected.subservice.length>0?
                        selected.subservice.map((item,i)=><SubServiceItem key={i} item={item} />)
                        : <Container margin='16px 0' align='flex-start' animate>Empty</Container>
            }
            <Container animate horizontal margin='16px 0'>
                <Button mtype='secondary' label={language.back} margin='0'
                width={isMobile?'120px':'200px'}
                onClick={()=>{
                    if(main) navigate.push('/?lang='+lang)
                    else setSelected({})
                }}/>
                {
                    selected && selected.subservice && selected.subservice.length>0?<>
                    <div style={{flex:1}} />
                    <div onClick={()=>navigate.push('/branches?lang='+lang)} style={{height:40,lineHeight:'40px',padding:'0 16px',textAlign:'center',width:134,fontWeight:'bold',cursor:'pointer'}}>{language.skip}</div>
                    <Button mtype={enable?'primary':'disable'} label={language.next} margin='0' onClick={()=>onItemClick(selectedSub)} margin='0 0 16px' width={isMobile?'134px':'200px'}/>
                    </>
                    :''
                }
            </Container>
            <Container flex={1} />
        </>
    }

    ////

    // const FormListing = () =>{
    //     const [formdata,setFormdata]    = useState(selected.required)
    //     const [counter,setCounter]      = useState(0)
    //     useEffect(()=>{
    //         if(counter<0) setSelected({});
    //         else if (counter === formdata.length) {
    //             const updated = {...selected,...{required:formdata}}
    //             setSelected(updated)
    //             dispatch(selectService(updated))
    //             navigate.push('/branches')
    //         }
    //     },[counter])
    //
    //
    //     const onBackClick = () =>{
    //         setCounter(counter-1);
    //     }
    //
    //     const onNextClick = () =>{
    //         setCounter(counter+1)
    //     }
    //
    //     return selected.required && selected.required[counter] ?
    //     <Container animate key={counter}>
    //         <Text Title alignself='start'>{selected.required[counter].label}</Text>
    //         <Text subtitle alignself='start'>{selected.required[counter].label}</Text>
    //         <Input type='text' margin='16px 0'
    //             value={formdata[counter].value??''}
    //             onChange={e=>setFormdata(formdata.map((item,i)=>counter === i?{...item,...{value:e.target.value}}:item))}/>
    //         <Container horizontal justify='space-between' wrap='wrap'>
    //             <Button mtype='secondary' label={'BACK'} margin='0' onClick={onBackClick} margin='0 0 16px' width={isMobile?'100px':'200px'}/>
    //             <Button mtype='primary'   label={'NEXT'} margin='0' onClick={onNextClick} margin='0 0 16px' width={isMobile?'100px':'200px'}/>
    //         </Container>
    //     </Container>
    //     :<></>
    // }

    const FormListing2 = () => {
        const [formdata,setFormdata] = useState(selected.required)

        const onBackClick = () =>{
            setSelected({})
        }

        const onNextClick = () =>{
            const updated = {...selected,...{required:formdata}}
            setSelected(updated)
            dispatch(selectService(updated))
            navigate.push('/branches?lang='+lang)
        }

        const isEnable = () =>{
            let allFilled = false;
            formdata.map((item,i)=>{
                //if(item.value !== undefined && item.value !== '') allFilled = true;
                if(item.value !== undefined && item.value !== ''){
                    if(item.validateby !== undefined){
                        switch (item.validateby) {
                            case 'value':
                            case 'values':
                                allFilled = item.value.length >= 0;
                                break;
                            case 'maxlength':
                                allFilled = item.value.length === (item.maxlength??15);
                                break;
                            default:
                                allFilled = true;
                        }
                    }else{
                        allFilled = true;
                    }
                    //allFilled = item.value.length === (item.maxlength??15)
                    //if(item.value.length === item.maxlength) allFilled = true;
                }
            })
            return allFilled?'primary':'disable'
        }

        return selected.required?
        <Container animate >
            <Text Title alignself='start'>{language.required_title}</Text>
            <Text subtitle alignself='start' margin='8px 0 40px'>{language.required_sub}</Text>
            <Container horizontal justify='space-between' wrap='wrap'>
            {
                selected.required.map((item,i)=>{
                    return <Container key={i} width={isMobile?'100%':'48%'} align='flex-start'>
                    <Text weight='bold'>{
                        item.label=='Amount' ? language.amount:
                        item.label=='Account No'? language.account:
                        item.label
                    }</Text>
                    <InputContainer margin='8px 0 16px'>
                        <Input
                            type={item.type??'text'}
                            maxLength={item.maxlength??15}
                            placeholder={
                                item.label=='Amount' ? language.amount:
                                item.label=='Account No'? language.account:
                                item.label
                            }
                            value={formdata[i].value??''}
                            onChange={e=>setFormdata(
                                formdata.map((jitem,j)=>
                                j === i?
                                {
                                    ...item,
                                    ...{value:e.target.value.length>(item.maxlength??15)?e.target.value.slice(0,item.maxlength??15):e.target.value }
                                }:jitem)
                            )}/>
                        <div style={{position:'absolute',right:16,top:16,color:'rgba(0,0,0,0.3)'}}>
                            {item.righthint}
                        </div>
                    </InputContainer>
                    </Container>
                })
            }
            </Container>
            <Container horizontal wrap='wrap'>
                <Button mtype='secondary' label={language.back} margin='0' onClick={onBackClick} margin='0 0 16px' width={isMobile?'134px':'200px'}/>
                <div style={{flex:1}} />
                {
                    isMobile?'':<>
                    <div
                    onClick={onNextClick}
                    style={{height:40,lineHeight:'40px',padding:'0 16px',textAlign:'center',width:134,fontWeight:'bold',cursor:'pointer'}}
                    >{language.skip}</div>
                    </>
                }
                <Button mtype={isEnable()} label={language.next} margin='0' onClick={onNextClick} margin='0 0 16px' width={isMobile?'134px':'200px'}/>
                {
                    isMobile?<div style={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <div
                    onClick={onNextClick}
                    style={{height:40,lineHeight:'40px',padding:'0 16px',textAlign:'center',width:134,fontWeight:'bold',cursor:'pointer'}}
                    >{language.skip}</div>
                    </div>:''
                }
            </Container>
        </Container>
        :<div />
    }

    return <Container flex={1} vertical justify='flex-start'>
        <Container maxwidth={isMobile?'400px':'800px'}>
            {
                Object.keys(selected).length === 0? <ServiceListing main /> :
                selected.subservice && selected.subservice.length>0? <ServiceListing />:
                selected.required && selected.required.length>0? <FormListing2 /> :
                ''
            }
        </Container>

        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={(e,reason)=>{toggle(false)}}
            message={errorMsg}
            action={<IconButton size="small" aria-label="close" color="inherit" onClick={(e,reason)=>{toggle(false)}}>x</IconButton>} />
    </Container>
}
export default Component;
