import React,{useState,useEffect} from 'react';
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {fetchService} from './../../data/api'
import {setService,selectCustomerType} from './../../data/actions'
import styled from 'styled-components'

import {Container,Divider,Text,Image} from './../../componentsV2/style'
import Button from './../../componentsV2/button'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

////////////////////////////////////////////////////////////////////////////////

const Component = ({type}) => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const config   = useSelector(state=>state.config)
    const lang     = useSelector(state=>state.data.language);
    const language = useSelector(state=>state.config.language[state.data.language??'en']);

    const [loading,setloading]  = useState(true)
    const [open,toggle]         = useState(false);
    const [errorMsg,setErrorMsg]= useState('')

    const [isProceed,setproceed]= useState(false);

    useEffect(()=>{
        if (type === undefined) return;
        if(lang === undefined) return;

        if(!isProceed){
            setproceed(true)
            onClick(type==='corporate');
        }
    },[type,lang])

    const onClick = async (coperate) =>{
        console.log("services");
        setloading(true)
        const response = await fetchService(lang,config,coperate);
        setloading(false)
        console.log(response);
        if(response.error){
            setErrorMsg(response.status+' : '+response.error)
            toggle(true)
        }else{
            dispatch(selectCustomerType(coperate?'12':'11'))
            dispatch(setService(response));
            navigate.push('/services?lang=' + lang)
        }
    }
    return <Container flex={1} vertical>
        <div>{loading?'loading...':errorMsg!==''?'Failed to load services':''}</div>
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={(e,reason)=>{toggle(false)}}
            message={errorMsg}
            action={<IconButton size="small" aria-label="close" color="inherit" onClick={(e,reason)=>{toggle(false)}}>x</IconButton>} />
    </Container>
}

// <Container vertical maxwidth={isMobile?'400px':'800px'} animate>
//     <Text Title>{language.welcome}</Text>
//     <Container horizontal card={!isMobile} margin='16px 0' wrap='wrap' justify='space-around'>
//         <Container maxwidth='290px' height='210px' margin='8px' padding='16px' card={isMobile}>
//             <Image src='./personal.png' height='100px'/>
//             <Button width='stretch' label={language.personal} mLoading={loading.personal} onClick={()=>onClick(false)}/>
//         </Container>
//         {isMobile?'':<Divider vertical margin='16px 0'/>}
//         <Container maxwidth='290px' height='210px' margin='8px' padding='16px' card={isMobile}>
//             <Image src='./coperate.png' height='100px'/>
//             <Button width='stretch' label={language.corporate} mLoading={loading.coperate} onClick={()=>onClick(true)}/>
//         </Container>
//     </Container>
// </Container>

export default Component;
