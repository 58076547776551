
export const SET_CONFIG         = "setconfig"
export const SET_BRANCH         = "setbranch";
export const SET_DEPT           = "setdepartment";
export const SET_SERV           = "setservice";
export const SET_TIMESLOT       = "settimeslot";
export const SET_APPOINTMENT    = "setappointment";
export const SET_LANGUAGE       = "setlanguage";

export const SELECTED_CUSTOMER  = "customer";
export const SELECTED_BRANCH    = "branch";
export const SELECTED_DEPT      = "department";
export const SELECTED_SERV      = "service";
export const SELECTED_TIMESLOT  = "timeslot";
