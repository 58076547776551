import React from 'react';
import styled,{keyframes} from 'styled-components'

////////////////////////////////////////////////////////////////////////////////

const mSlides = keyframes`
    0% { opacity:0; transform:  translate(0px,40px);}
    20% { opacity:0; transform:  translate(0px,40px);}
    100% { opacity:1; transform:  translate(0px,0px);}
`
const mStill = keyframes`
    0% {}
    100% {}
`

////////////////////////////////////////////////////////////////////////////////

export const Container = styled.div`
    margin          : ${p=>p.margin};
    padding         : ${p=>p.padding};
    width           : ${p=>p.width??'stretch'};
    height          : ${p=>p.height};
    min-width       : ${p=>p.minwidth};
    max-width       : ${p=>p.maxwidth};
    flex            : ${p=>p.flex};
    flex-shrink     : ${p=>p.shrink??0};
    display         : ${p=>p.show??'flex'};
    flex-direction  : ${p=>p.horizontal || p.centerhorizontal?'row':'column'};
    align-items     : ${p=>p.align??( p.horizontal || p.vertical? 'flex-start':'center' )};
    justify-content : ${p=>p.justify??( p.horizontal || p.vertical?'flex-start':'center' )};
    flex-wrap       : ${p=>p.wrap};
    border          : 1px solid ${p=>
        p.selected? 'rgb(237,28,36)':
        p.card? 'rgba(102,102,102,0.2)':
        'transparent'};
    border-radius   : ${p=>p.card?'10px':'0px'};
    box-shadow      : ${p=>p.card?'0px 2px 3px 0px rgba(20,50,80,0.05)':''};
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
    background      : ${p=>p.background??'white'};
    z-index         : ${p=>p.zindex};
    overflow        : ${p=>p.overflow};
    overflow-x      : ${p=>p.overflowx};
    overflow-y      : ${p=>p.overflowy};
    position        : ${p=>p.position};
    top             : ${p=>p.top};
    bottom          : ${p=>p.bottom};


    &:hover{
        border: ${p=>p.hoverable?'1px solid rgb(237,28,36)':''};
    }
`;

////////////////////////////////////////////////////////////////////////////////

export const Dialog = styled.div`
    display         : ${p=>p.show??'flex'};
    flex-direction  : column;
    align-items     : center;
    justify-content : center;
    z-index         : 100;
    position        : absolute;
    top             : 0;
    left            : 0;
    right           : 0;
    bottom          : 0;
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
    background      : white;
    padding         : 16px;
`;

////////////////////////////////////////////////////////////////////////////////

export const Divider = styled.div`
    margin      : ${p=>p.margin};
    min-width   :  ${p=>p.vertical? '1px': 'stretch'};
    min-height  : ${p=>p.vertical? 'stretch': '1px'};
    background  : rgba(102,102,102,0.2);
    align-self  : stretch;
`;

////////////////////////////////////////////////////////////////////////////////

export const Text = styled.div`
    margin          : ${p=>p.margin};
    padding         : ${p=>p.padding};
    flex            : ${p=>p.flex};
    display         : flex;
    flex-shrink     : 0;
    font-size       : ${p=>p.fontsize??(p.Title?'24px':'14px')};
    font-weight     : ${p=>p.weight??(p.Title?'bold':'')};
    text-align      : ${p=>p.align};
    color           : ${p=>p.color??(p.subtitle?'rgb(153,153,153)':p.selected?'rgb(237,28,36)':'rgb(51,51,51)')};
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
    align-self      : ${p=>p.alignself};

`

////////////////////////////////////////////////////////////////////////////////

export const Image = styled.img`
    margin          : ${p=>p.margin};
    padding         : ${p=>p.padding};
    flex            : ${p=>p.flex};
    display         : flex;
    flex-shrink     : 0;
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
`

export const ImageIcon = styled.img`
    width           : 100%;
    height          : 100%;
    border          : 1px solid rgba(0,0,0,0.1);
    border-radius   : 50%;
    margin          : ${p=>p.margin};
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
`

////////////////////////////////////////////////////////////////////////////////

export const InputContainer = styled.div`
    margin          : ${p=>p.margin};
    padding         : ${p=>p.padding};
    border-radius   : 4px;
    border          : 1px solid rgba(102,102,102,0.2);
    width           : ${p=>p.width??'stretch'};
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};
    position        : relative;

    &:focus{
        outline: none !important;
        border-color: rgb(237,28,36);
    }
`


export const Input = styled.input`
    margin          : ${p=>p.margin};
    padding         : ${p=>p.padding??'16px'};
    font-size       : ${p=>p.fontsize??'14px'};
    line-height     : ${p=>p.fontsize??'14px'};
    color           : rgb(51,51,51);
    border-radius   : 4px;
    border          : 1px solid rgba(102,102,102,0);
    width           : ${p=>p.width??'stretch'};
    -webkit-appearance: none;
    -moz-appearance : textfield;
    animation       : ${p=>p.duration??'1'}s ease 0s normal forwards 1 ${p=>p.animate?mSlides:mStill};

    &:focus{
        outline: none !important;
        border-color: rgb(237,28,36);
    }
`

////////////////////////////////////////////////////////////////////////////////

const mRotate = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg);}
`

export const Loading = styled.div`
    display: inline-block;
    margin: 4.5px;

    &:after{
        content: " ";
        display: block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid rgb(237,28,36);
        border-color: rgb(237,28,36) transparent rgb(237,28,36) transparent;
        animation: ${mRotate} 1.2s linear infinite;
    }

    &:hover{
        background: ${p=>
                p.type === 'primary'? 'rgba(220,0,8,1)':
                p.type === 'secondary'? 'darkgrey':''
            };
    }
`;

////////////////////////////////////////////////////////////////////////////////
