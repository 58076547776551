
////////////////////////////////////////////////////////////////////////////////
export const fetchConfig = async () => {
    const response = await fetch('config.json').then(response => response.json())
    return response;
}
export async function mfetch(url,data={}){
    const config = await fetchConfig();
    const headers = new Headers();
    // headers.append('Authorization', 'Basic ' + Buffer.from(config.id+":"+config.pass).toString('base64'));
    const response = await fetch(url, {
        method:'POST',
        headers: headers,
        body:toFormData(data)})
    .then(response =>response.text())
    .then(response =>{
        if(response.startsWith('<!DOCTYPE')) return {status:404,error:'Failed to fetch API'}
        response = JSON.parse(response);
        if (response.status === 200) return response.data;
        return ({status:response.status??500,error:response.message ?? "Invalid Response",error_code:response.error_code})
    })
    .catch(error=>{return {error:error.message,status:500} }); //error.message
    return response;
}
function toFormData(obj){
    let formData = new FormData();
    Object.keys(obj).map(key=>formData.append(key,obj[key]));
    return formData;
}
const delay = ms => new Promise(res => setTimeout(res, ms));
////////////////////////////////////////////////////////////////////////////////

export const fetchService = async (language,config,corperate) =>{
    //await delay(500); return sampleServices;
    return await mfetch(config.api+'/listserviceweb.php',{
        language    :language??'en',
        serviceid   :'',
        branchid    :'',
        deptid      :'',
        type        :corperate?12:11,
        channel     :'2'
    })
}

export const fetchBranches = async (language,config,servid,coordinate) =>{
    //await delay(500); return sampleBranches;
    return await mfetch(config.api+'/listbranch.php',{
        language    :language??'en',
        serviceid   :servid,
        branchid    :'',
        deptid      :'',
        user_lat    :coordinate.lat,
        user_lng    :coordinate.lng,
        channel     :'2'
    })
}

export const fetchDepartments = async () =>{

}

export const fetchTimeslots = async (language,config,servid,branchid,deptid) =>{
    //await delay(500); return sampletimeslot;
    return await mfetch(config.api+'/getavailslot.php',{
        language    :language??'en',
        cust_id     :'12345678',
        cust_id_type:'9',
        serviceid   :servid,
        branchid    :branchid,
        deptid      :deptid
    })
}

export const bookAppointment = async (language,config,custtype,services,branchid,deptid,date,time,custidtype,custid,custname,custphone,custemail,accountnum,captchakey) =>{
    //await delay(500); return sampleappointment;
    // return {
    //     "status": 200,
    //     "error_code": "209",
    //     "error":"Cant book on this date",
    //     "message": "Cant book on this date"
    // }
    return await mfetch(config.api+'/apmt_submit_web.php',{
        language    : language??'en',
        services    : services,
        branchid    : branchid,
        deptid      : deptid,
        apmt_date   : date,
        apmt_time   : time,
        cust_id     : '',
        cust_name   : custname,
        phone       : custphone,
        email       : custemail,
        type        : custtype,
        cust_id_type: custidtype,       //4,             //5 cause idtype is email
        source      : 1,                //1 from web
        accno       : accountnum,
        captchakey  : captchakey,
    })
}

export const validateV3 = async (secret,token) => {
    const response = await fetch('https://www.google.com/recaptcha/api/siteverify', {
        method:'POST',
        body:toFormData({
            secret      : secret,
            response    : token
        })})
    .then(response =>response.json())
    .then(response =>{
        return response;
    })
    .catch(error=>{return {success:false} }); //error.message
    return response;
}

////////////////////////////////////////////////////////////////////////////////

const sampleServices = [
        {
            "servid": 61,
            "servnm": "Nộp Rút Tiền, Chuyển Tiền, Sao Kê, Sec",
            "subservice": [
                {
                    "servid": 71,
                    "amountlimit": "50000000",
                    "servnm": "Nộp tiền",
                    "mainservid": 61,
                    "required": [
                        {
                            "label": "Amount",
                            "type": "number",
                            "righthint": "VND",
                            "maxlength":12,
                            "validateby":"values"
                        },
                        {
                            "label": "Account No",
                            "type": "number",
                            "maxlength":13,
                            "validateby":"maxlength"
                        }
                    ]
                },
                {
                    "servid": 72,
                    "amountlimit": "50000000",
                    "servnm": "Rút tiền từ tài khoản",
                    "mainservid": 61,
                    "required": [
                        {
                            "label": "Amount",
                            "type": "number",
                            "maxlength": 13,
                            "righthint": "VND",
                            "validateby": "values"
                        },
                        {
                            "label": "Account No",
                            "type": "number",
                            "maxlength": 14,
                            "validateby": "maxlength"
                        }
                    ]
                },
                {
                    "servid": 73,
                    "amountlimit": "-1",
                    "servnm": "Rút tiền bằng Sec",
                    "mainservid": 61,
                    "required": []
                },
                {
                    "servid": 74,
                    "amountlimit": "-1",
                    "servnm": "Chuyển tiền trong hệ thống",
                    "mainservid": 61,
                    "required": []
                },
                {
                    "servid": 75,
                    "amountlimit": "-1",
                    "servnm": "Chuyển tiền ngoài hệ thống",
                    "mainservid": 61,
                    "required": []
                }
            ]
        },
        {
            "servid": 62,
            "servnm": "Thanh Toán, Mua Bán Ngoại Tệ, Western Union",
            "subservice": []
        },
        {
            "servid": 63,
            "servnm": "Tài Khoản, Ngân Hàng Điện Tử, Thẻ Ghi Nợ",
            "subservice": []
        },
        {
            "servid": 64,
            "servnm": "Tư Vấn Sản Phẩm Tài Chính(Tiết kiệm, Thẻ tín dụng, Bảo hiểm, Vay…)",
            "subservice": []
        }
    ]

const sampleBranches = {
        "branches": [
            {
                "branchid": "2",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "21",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "22",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "23",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "24",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "25",
                "name": "Hưng Yên",
                "address": "No. 05 Nguyen Binh, My Hao, Hung Yen",
                "latitude": 20.939,
                "longitude": 106.06,
                "city_id": "101",
                "district_id": "0",
                "branch_code": "HYN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "1",
                "name": "Hải Phòng",
                "address": "Số 5 Lý Tự Trọng, Hồng Bàng, Hải Phòng",
                "latitude": 20.8609,
                "longitude": 106.687,
                "city_id": "100",
                "district_id": "150",
                "branch_code": "HPG",
                "cust_loc_dist": 0
            },
            {
                "branchid": "4",
                "name": "Kiến An",
                "address": "Số 216 Trần Thành Ngọ, Kiến An, Hải Phòng",
                "latitude": 20.8092,
                "longitude": 106.626,
                "city_id": "100",
                "district_id": "153",
                "branch_code": "KAN",
                "cust_loc_dist": 0
            },
            {
                "branchid": "6",
                "name": "Quảng Ninh",
                "address": "Gian số L1 - 19, L2  - 10B, tầng L trung tâm TM Vincom Center - Hạ Long, Hạ Long, Quảng Ninh",
                "latitude": 20.9501,
                "longitude": 107.084,
                "city_id": "104",
                "district_id": "155",
                "branch_code": "QNH",
                "cust_loc_dist": 0
            }
        ],
        "dis_city": [
            {
                "city_id": "100",
                "cityname": "Hải Phòng",
                "districts": [
                    {
                        "district_id": "150",
                        "district_name": "Hồng Bàng"
                    },
                    {
                        "district_id": "153",
                        "district_name": "Kiến An"
                    },
                    {
                        "district_id": "154",
                        "district_name": "Ngô Quyền"
                    },
                    {
                        "district_id": "156",
                        "district_name": "Lê Chân"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    }
                ]
            },
            {
                "city_id": "101",
                "cityname": "Hưng Yên",
                "districts": [
                    [],
                    {
                        "district_id": "153",
                        "district_name": "Kiến An"
                    },
                    {
                        "district_id": "154",
                        "district_name": "Ngô Quyền"
                    },
                    {
                        "district_id": "156",
                        "district_name": "Lê Chân"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    }
                ]
            },
            {
                "city_id": "104",
                "cityname": "Quảng Ninh",
                "districts": [
                    {
                        "district_id": "155",
                        "district_name": "Hạ Long"
                    },
                    {
                        "district_id": "157",
                        "district_name": "Móng Cái"
                    },
                    {
                        "district_id": "154",
                        "district_name": "Ngô Quyền"
                    },
                    {
                        "district_id": "156",
                        "district_name": "Lê Chân"
                    },
                    {
                        "district_id": "158",
                        "district_name": "Thủy Nguyên"
                    }
                ]
            }
        ]
    }

const sampletimeslot = {
    "isfull":1,
    "slot": [
        {
            "date": "2020-12-17",
            "slotam": [
                {
                    "time": "07:00",
                    "maxslot": 30,
                    "slotbal": 0,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "07:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:00",
                    "maxslot": 30,
                    "slotbal": 27,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:30",
                    "maxslot": 30,
                    "slotbal": 27,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:00",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                }
            ],
            "slotpm": [
                {
                    "time": "12:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "12:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:00",
                    "maxslot": 30,
                    "slotbal": 25,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:30",
                    "maxslot": 30,
                    "slotbal": 27,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                }
            ]
        },
        {
            "date": "2020-12-18",
            "slotam": [
                {
                    "time": "07:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "07:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                }
            ],
            "slotpm": [
                {
                    "time": "12:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "12:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:30",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                }
            ]
        },
        {
            "date": "2020-10-30",
            "slotam": [
                {
                    "time": "07:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "07:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "08:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "09:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "10:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:00",
                    "maxslot": 30,
                    "slotbal": 29,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "11:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                }
            ],
            "slotpm": [
                {
                    "time": "12:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "12:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "13:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "14:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "15:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 0
                },
                {
                    "time": "16:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "17:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "18:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:00",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                },
                {
                    "time": "19:30",
                    "maxslot": 30,
                    "slotbal": 30,
                    "isdupl": 0,
                    "enable": 1
                }
            ]
        }
    ]
}

const sampletimeslot2 = {
    "isfull":0,
    "slot":[
        {
            "date":"2020-11-19",
            "isfull":1,
            "slotam":[
                {"time":"08:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"08:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"09:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"09:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"10:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"10:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"11:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"11:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0}
            ],
            "slotpm":[
                {"time":"13:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"14:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"14:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"15:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"15:30","maxslot":5,"slotbal":1,"isdupl":0,"enable":0},
                {"time":"16:00","maxslot":5,"slotbal":1,"isdupl":0,"enable":0}
            ]
        },{
            "date":"2020-11-20",
            "isfull":0,
            "slotam":[
                {"time":"08:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"08:30","maxslot":5,"slotbal":4,"isdupl":0,"enable":1},
                {"time":"09:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"09:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"10:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"10:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"11:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"11:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1}
            ],
            "slotpm":[
                {"time":"13:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"14:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"14:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"15:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"15:30","maxslot":5,"slotbal":5,"isdupl":0,"enable":1},
                {"time":"16:00","maxslot":5,"slotbal":5,"isdupl":0,"enable":1}
            ]
        }
    ]
}

const sampleappointment = {
    "apmt_code": "855043",
    "apmt_id": 22,
    "apmt_date": "2020-10-23",
    "apmt_time": "16:00",
    "branchid": 2,
    "branch_name": "Hưng Yên",
    "branch_address": "test1",
    "cust_id": "fz1@gmail.com",
    "cust_id_type": 9,
    "phone": "",
    "email": "<h1>HTML String</h1><p>Stringy</p>",
    "services": "{\"servid\":\"61\",\"servnm\":\"Money Widrawal\",\"required\":[{\"label\":\"Amount\",\"value\":\"5000\"},{\"label\":\"Account No\",\"value\":\"109837655\"}]}",
    "apmt_qr": "https://qs.techcombank.com.vn/q/S47QqMcRWDpJ26VIBJSzw7rEtXkrVPttKoUBKeTt1EU%3D"
}
