import * as actions from "./../actions/actiontype";
import produce,{enableES5} from "immer";

const selectReducer = (state={},action) =>{
    enableES5()
    return produce(state,draft =>{
        switch (action.type) {
            case actions.SELECTED_CUSTOMER:
                draft.customer_type = action.payload;
                break;
            case actions.SELECTED_BRANCH:
                draft.branch = action.payload;
                break;
            case actions.SELECTED_DEPT:
                draft.department   = action.payload;
                break;
            case actions.SELECTED_SERV:
                draft.service   = action.payload;
                break;
            case actions.SELECTED_TIMESLOT:
                draft.timeslot = action.payload;
                break;
            default:
                return draft;
        }
    })
}
export default selectReducer;
