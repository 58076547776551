import React,{useState,useEffect} from 'react';
import {useMediaQuery} from 'react-responsive'
import {useHistory} from 'react-router-dom'
import {useDispatch,useSelector} from 'react-redux';
import {fetchBranches,fetchTimeslots} from './../../data/api'
import {selectBranch,selectTimeslot} from './../../data/actions'
import styled from 'styled-components'

import {Container,Divider,Text,Image,ImageIcon,Dialog,Loading} from './../../componentsV2/style'
import Button from './../../componentsV2/button'
import Select from './../../componentsV2/select'
import Search from './../../componentsV2/search'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import {Icon,InlineIcon} from '@iconify/react';
import mapMarker from '@iconify/icons-mdi/map-marker';
import chevronDown from '@iconify/icons-mdi/chevron-down';
import chevronUp from '@iconify/icons-mdi/chevron-up';
import Moment from 'react-moment';
import moment from 'moment';


const TimeItem = styled.div`
    background      : ${p=>p.enable?'white':'rgb(240,240,240)'};
    margin          : ${p=>p.isMobile?'6px 5px':'6px 12px'};
    width           : ${p=>p.isMobile?'76px':'100px'};
    height          : 40px;
    border-radius   : 4px;
    border          : 1px solid ${p=>p.selected?'rgb(237,28,36)':'rgb(213,213,213)'};
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    user-select     : none;
`;

////////////////////////////////////////////////////////////////////////////////

const Component = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 700px)' })
    const config   = useSelector(state=>state.config)
    const lang     = useSelector(state=>state.data.language);
    const language = useSelector(state=>state.config.language[state.data.language??'en']);
    const [loading,setLoading]  = useState({branch:false,timeslot:false})
    const [open,toggle]         = useState(false);
    const [errorMsg,setErrorMsg]= useState('')
    const [show,setShow]        = useState(true);
    const [geoEnable,setGeo]    = useState(false);
    const [selected,setSelected]= useState({});
    const [branches,setBranches]= useState([]);
    const [cities,setCities]    = useState([]);
    const [filtered,setFiltered]= useState([]);
    const [filter,setFilter]    = useState({city:'',district:'',keyword:''});
    const [timeslots,setTimeslots] = useState([]);
    const [date,setDate]        = useState({});
    const [time,setTime]        = useState({});
    const [isFull,setIsFull]    = useState(false);
    const [nextEnable,setNextEnable] = useState(false);

    useEffect(()=>{
        try{
            console.log(navigator.geolocation);
            navigator.permissions.query({name:'geolocation'})
            .then(function(result){
                if(result.state === 'granted'){
                    setGeo(true);
                    rerequestlocation();
                    //loadBranches();
                }else if (result.state === 'denied') {
                    setGeo(true);
                    loadBranches();
                }
            })
            return ()=>setGeo(false)
        }catch(e){
            console.log(e);
        }

    },[])

    const selectedService  = useSelector(state=>state.select.service)
    useEffect(()=>{ if(selectedService === undefined) navigate.push('/?lang='+(lang===undefined?'en':lang))},[selectedService])

    useEffect(()=>{
        const validCity     = filter.city.city_id !== undefined;
        const validDistrict = filter.district.district_id !==undefined;

        let updated = branches.filter(el=>{
            if(validCity && validDistrict ){
                return el.city_id===filter.city.city_id && el.district_id === filter.district.district_id;
            }else if(validCity && !validDistrict){
                return el.city_id===filter.city.city_id;
            }else if(!validCity && validDistrict){
                return el.district_id === filter.district.district_id;
            }else{
                return true
            }
        })

        if(filter.keyword.length>0){
            console.log(filter.keyword);
            updated = updated.filter(el=>{
                return el.name.toLowerCase().includes(filter.keyword.toLowerCase()) ||
                el.address.toLowerCase().includes(filter.keyword.toLowerCase())
            })
            updated = updated.slice(0,5);
        }
        setSelected({})
        setFiltered(updated)
    },[filter])

    useEffect(()=>{
        if(date.slotam && date.slotpm){
            setTime([...date.slotam,...date.slotpm].find(el=>el.enable===1 && el.slotbal>0))
        }

    },[date])

    useEffect(()=>{
        setNextEnable(
            (selected !== undefined && Object.keys(selected).length !== 0) &&
            (date !== undefined && Object.keys(date).length !== 0) &&
            (time !== undefined && Object.keys(time).length) !== 0
        )
    },[selected,date,time])

    const rerequestlocation = () =>{
        navigator.geolocation.getCurrentPosition(
            location=>loadBranches(location),
            err=>loadBranches())
    }

    const loadBranches = async (location) =>{
        if(selectedService === undefined) return;
        const coordinate = {
            lat:location === undefined || location.coords === undefined?'':location.coords.latitude,
            lng:location === undefined || location.coords === undefined?'':location.coords.longitude}

        setShow(false)
        setLoading({...loading,...{branch:true}});
        const response = await fetchBranches(lang,config,selectedService.servid,coordinate)
        console.log('branches:');
        console.log(response);
        setLoading({...loading,...{branch:false}});
        if(response.error){
            setErrorMsg(response.status+' : '+response.error)
            toggle(true);
        }else{
            setBranches(response.branches)
            setFiltered(response.branches)
            setCities(response.dis_city)
        }
    }

    const onNextClick = () =>{
        dispatch(selectBranch(selected))
        dispatch(selectTimeslot({date:date.date,time:time.time}));
        navigate.push('/forms?lang='+lang)
    }

    const BranchItem = ({item}) =>{
        const shouldAnimate = Object.keys(selected).length === 0
        const isSelected = item.branchid === selected.branchid;

        const onItemClick = async () =>{
            setSelected(item)
            setLoading({...loading,...{timeslot:true}});
            const response = await fetchTimeslots(lang,config,selectedService.servid,item.branchid,1);
            console.log('timeslot=>');
            console.log(response);
            setLoading({...loading,...{timeslot:false}});
            if(response.error){
                setErrorMsg(response.status+' : '+response.error)
                toggle(true);
            }else{
                if(response.slot.length === 0){
                    setTimeslots([])
                    setErrorMsg('Not Available');
                    toggle(true)
                }else{
                    setIsFull(response.isfull == 1)
                    setTimeslots(response.slot);
                    setDate(response.slot[0])
                }
            }
        }

        return <Container card margin='12px 0 0' hoverable animate={shouldAnimate} selected={isSelected}>
            <Container horizontal padding='16px' background='transparent' align='center' onClick={onItemClick}>
                <Container width='50px' height='50px'><img src='./branchicon.png' width='50' height='50'/></Container>
                <Container align='flex-start' margin='0 16px' shrink={1} >
                    <Container horizontal align='center'>
                        <Text weight='bold' margin='0 8px 0 0'>{item.name}</Text>
                        {
                            isSelected && isFull?
                            <div style={{fontSize:12,color:'red',background:'rgb(255,232,233)',padding:'2px 8px',border:'1px solid rgb(237,28,36)',borderRadius:5}}>{language.full}</div>:
                            ''
                        }

                    </Container>
                    <Text subtitle><InlineIcon icon={mapMarker} style={{width:16,height:16,margin:'0 8px 0 0'}}/>{item.address}</Text>
                </Container>
                <Icon icon={isSelected?chevronUp:chevronDown} style={{ fontSize: '24px',color:'red'}}/>
            </Container>
            {
                !isSelected?'':
                <>
                    <Divider />
                    <Container margin='0 0 6px'>
                    {
                        loading.timeslot?<Loading />:
                        timeslots.length === 0?<Container padding='16px'>Not Available</Container>:
                        <TimeslotsItem />
                    }
                    </Container>
                </>
            }
        </Container>
    }

    const dateToFromNowDaily = (myDate) => {
        var fromNow = moment(myDate).fromNow();
        var days    = moment(myDate).calendar(null,{
            lastWeek: '[Last] dddd',
            lastDay:  '[Yesterday]',
            sameDay:  '['+language.today+']',
            nextDay:  '['+language.tomorrow+']',
            nextWeek: 'dddd',
            // when the date is further away, use from-now functionality
            sameElse: function () {return "[" + fromNow + "]";}
        })

        switch (days) {
            case 'Monday'   : return language.monday;
            case 'Tuesday'  : return language.tuesday;
            case 'Wednesday': return language.wednesday;
            case 'Thursday' : return language.thursday;
            case 'Friday'   : return language.friday;
            case 'Saturday' : return language.friday;
            default: return days;
        }

    }

    const TimeslotsItem = () =>{
        return timeslots.length === 0?'':
        <>
        <Container horizontal wrap='nowrap' overflow='hidden'>{
            timeslots.map((item,i)=><Container key={i} minwidth='50%' padding='8px 0 0' onClick={()=>setDate(item)} shrink={1}>
            <Text selected={item.date === date.date} fontsize='12px'>
                {dateToFromNowDaily(item.date)}
            </Text>
            <Text selected={item.date === date.date} fontsize='16px' weight='bold'><Moment format="DD/MM">{item.date}</Moment></Text>
            { item.date === date.date?<Container background='red' height='1px' margin='8px 0 0'/>:'' }
            </Container>)
        }</Container>
        <Container horizontal wrap='wrap' padding='8px'>
        {
            date.slotam && date.slotpm ?
            [...date.slotam,...date.slotpm].map((item,i)=><TimeItem key={i}
                    isMobile={isMobile}
                    enable={item.slotbal>0 && item.enable === 1}
                    selected={time === undefined?false:item.time === time.time}
                    onClick={()=>{
                        if(item.slotbal !== 0 && item.enable ===1) setTime(item)
                    }}>
                    <Text>{item.time}</Text>
                    {item.slotbal === 0?<Text fontsize='12px'>{language.full}</Text>:''}
                </TimeItem>):''
        }
        </Container>
        </>
    }

    // <div>{JSON.stringify(selected)}</div>
    // <div>-</div>
    // <div>{JSON.stringify(date.date)}</div>
    // <div>-</div>
    // <div>{JSON.stringify(time.time)}</div>

    return <Container vertical flex={1}>
        <Container vertical maxwidth={isMobile?'400px':'800px'}>
            <Text Title animate>{language.branch_title}</Text>
            <Text subtitle animate>{language.branch_sub}</Text>
            <Container horizontal align='center' wrap='wrap' zindex={99} padding='8px 0' animate>
                <Select
                    items={cities}
                    margin={'8px 0'}
                    isMobile={isMobile}
                    itemlabel={language.city}
                    childlabel={language.districts}
                    onItemClick={(i)=>setFilter({...filter,...{city:i}})}
                    onChildClick={(i)=>setFilter({...filter,...{district:i}})}
                />
                {isMobile?'':<Container width='0px' flex={1} />}
                <Search margin={'8px 0'} label={language.search} hint={language.branch_search} width={isMobile?'400px':'330px'} onSearchClick={i=>setFilter({...filter,...{keyword:i}})}/>
            </Container>

            <Text weight='bold' animate>{language.branch_result}</Text>
            {
                loading.branch?<Container animate>Loading...</Container>:
                filtered.length === 0? <Container animate>No Result</Container>:
                filtered.map((item,i)=><BranchItem key={i} item={item} />)
            }
            <Container horizontal justify='space-between' wrap='wrap' padding='8px 0' animate>
                <Button mtype='secondary' label={language.back} margin='8px 0' onClick={()=>window.history.back()} width={isMobile?'100%':'200px'}/>
                <Button mtype={nextEnable?'primary':'disable'} label={language.next} margin='8px 0' onClick={onNextClick} width={isMobile?'100%':'200px'}/>
            </Container>
        </Container>

        <Dialog show={show && !geoEnable?'flex':'none'}>
            <Text Title align='center' animate>{language.location_title}<br/>{language.location_title2}</Text>
            <Image height='200px' margin='32px' src='./location.png' animate/>
            <Container centerhorizontal wrap='wrap' animate>
                <Button label={language.close} mtype='secondary' onClick={()=>{loadBranches()}}/>
                <Button label={language.allow} mtype='primary' onClick={()=>rerequestlocation()}/>
            </Container>
        </Dialog>

        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={(e,reason)=>{toggle(false)}}
            message={errorMsg}
            action={<IconButton size="small" aria-label="close" color="inherit" onClick={(e,reason)=>{toggle(false)}}>x</IconButton>} />
    </Container>
}
export default Component;
