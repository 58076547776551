import React from 'react';
import styled from 'styled-components'

////////////////////////////////////////////////////////////////////////////////

// export const Container = styled.div`
//     display: ${p=>p.show??'flex'};
//     flex-direction: ${p=>p.horizontal?'row':'column'};
//     align-items: ${p=>p.align??'center'};
//     justify-content: ${p=>p.vertical??'center'};
//     width: ${p=>p.width};
//     margin:${p=>p.margin};
//     padding: ${p=>p.padding};
//     overflow:hidden;
//     flex-wrap: ${p=>p.wrap??''};
//     flex:${p=>p.flex??1};
//     align-self:${p=>p.alignSelf};
// `;

export const Center = styled.div`
    flex: 1;
    display: flex;
    flex-direction: ${p=>p.horizontal?'row':'column'};
    align-items: center;
    justify-content: ${p=>p.vertical??'center'};
    width: ${p=>p.width};
`;

// export const Card = styled.div`
//     margin: ${p=>p.margin};
//     min-height: 68px;
//     width: ${p=>p.width};
//     padding: ${p=>p.padding??'16px'};
//     display: flex;
//     flex-direction: ${p=>p.horizontal?'row':'column'};
//     align-items: center;
//     border-radius: 10px;
//     border: ${p=>p.selected?'1px solid rgb(237,28,36)':'1px solid rgba(102,102,102,0.2)'};
//     box-shadow: 0px 2px 3px 0px rgba(20,50,80,0.05);
//     background: white;
//     flex-wrap: wrap;
//
//     &:hover{
//         border: ${p=>p.hoverable?'1px solid rgb(237,28,36)':''};
//     }
// `;

export const Container = styled.div`
    margin: ${p=>p.margin};
    padding: ${p=>p.padding};
    width: ${p=>p.width??'auto'};
    display: ${p=>p.show??'flex'};
    flex-wrap: ${p=>p.wrap??''};
    flex:${p=>p.flex};

    overflow-x: hidden;
    overflow-y: auto;
    align-self:${p=>p.alignSelf};
    flex-direction: ${p=> p.horizontal || p.centerhorizontal ? 'row':'column'};
    align-items: ${p=>p.align??(p.center || p.centerhorizontal || p.centervertical ?'center':'flex-start')};
    justify-content: ${p=>p.justify??(p.center || p.centerhorizontal || p.centervertical? 'center':'flex-start')};
`;
export const Card = styled.div`
    margin: ${p=>p.margin};
    padding: ${p=>p.padding};
    width: ${p=>p.width??'auto'};
    display: ${p=>p.show??'flex'};
    flex-wrap: ${p=>p.wrap??''};
    flex:${p=>p.flex};
    overflow:hidden;
    align-self:${p=>p.alignSelf};
    flex-direction: ${p=> p.horizontal || p.centerhorizontal ? 'row':'column'};
    align-items: ${p=>p.align??(p.center || p.centerhorizontal || p.centervertical ?'center':'flex-start')};
    justify-content: ${p=>p.justify??(p.center || p.centerhorizontal || p.centervertical? 'center':'flex-start')};
    border: 1px solid rgba(102,102,102,0.2);
    border-radius: 10px;
    box-shadow: 0px 2px 3px 0px rgba(20,50,80,0.05);

    &:hover{
        border: ${p=>p.hoverable?'1px solid rgb(237,28,36)':''};
    }
`;

export const Image = styled.img`
    margin: ${p=>p.margin};
    padding: ${p=>p.padding};
    width: ${p=>p.width};
    height: ${p=>p.height};
`

export const Divider = styled.div`
    min-width:  ${p=>p.vertical? '1px': 'stretch'};
    min-height: ${p=>p.vertical? 'stretch': '1px'};
    background: rgba(102,102,102,0.2);
    align-self: stretch;
`;

export const Placeholder = styled.div`
    margin: ${p=>p.margin};
    width:  ${p=>p.width?? '100px'};
    height: ${p=>p.height??'100px'};
    background: whitesmoke;
`;

////////////////////////////////////////////////////////////////////////////////

export const Title = styled.div`
    font-size: ${p=>p.fontsize??'24px'};
    color: rgb(38,38,38);
    font-weight: bold;
    margin: ${p=>p.margin};
    text-align: ${p=>p.align};
    width: ${p=>p.width};
`;

export const Text = styled.div`
    width: ${p=>p.width};
    text-align: ${p=>p.align};
    font-size: ${p=>p.fontsize??(
        p.Title?'24px':
        '14px')};
    color: ${p=>p.color??(p=>
        p.Title?'rgb(51,51,51)':
        p.subtitle?'rgb(153,153,153)':
        '')};
    font-weight: ${p=>p.weight??(
        p.Title?'bold'
        :'')};
    margin: ${p=>p.margin};
`;

export const Subtitle = styled.div`
    font-size: ${p=>p.fontsize??'14px'};
    color: rgb(153,153,153);
    margin: ${p=>p.margin};
    text-align: ${p=>p.align??''};
`;

////////////////////////////////////////////////////////////////////////////////

export const InputText = styled.input`
    font-size: ${p=>p.fontsize??'14px'};
    color: rgb(51,51,51);
    padding: 16px;
    margin: ${p=>p.margin};
    border-radius: 4px;
    border: 1px solid rgba(102,102,102,0.2);
    width: ${p=>p.width};

    &:focus{
        outline: none !important;
        border-color: rgb(237,28,36);
    }
`;
