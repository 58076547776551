import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
    padding: ${p=>p.padding};
    margin: ${p=>p.margin};
    width: ${p=>p.width};
`;

const Label = styled.div`
    margin: 0 0 8px;
    font-weight: bold;
    font-size: 14px;
    color : ${p=>p.color};
`;

const Input = styled.input`
    width:stretch;
    padding: 14px 16px;
    border: 1px solid rgba(102,102,102,0.2);
    border-radius: 4px;
    outline-color:rgb(237,28,36);
`;

////////////////////////////////////////////////////////////////////////////////

const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

const validatePhone = (phone) =>{
    return phone.length === 10;
}

////////////////////////////////////////////////////////////////////////////////

const Component = ({label='Button',redlabel,labelcolor,hint,mtype='text',width,padding,margin='',value,onChange=()=>{},onblur=()=>{},isValid=()=>{},invalidString='error',maxLength}) => {
    const [tempValue,setTempValue] = useState('');
    const [invalid,setInvalid]     = useState(false)

    // useEffect(()=>{
    //     isValid(!invalid);
    // },[invalid]);

    useEffect(()=>{
        if(tempValue === ''){ setInvalid(false); return; }
        // if(tempValue.length < 5){setInvalid(false); return; }
        // const emailValidate = validateEmail(tempValue);
        // setInvalid(!emailValidate);
        if(mtype==='email') setInvalid(!validateEmail(tempValue))
        else if(mtype === 'tel') setInvalid(!validatePhone(tempValue))
    },[tempValue])

    return <Container margin={margin} width={width} padding={padding}>
        <div style={{display:'flex',alignItems:'center'}}>
            <Label color={labelcolor}>{label}</Label>
            <Label color={'red'}>{redlabel}</Label>
            <div style={{flex:1}} />
        </div>
        <Input
            type={mtype==='tel'?'number':mtype}
            value={value}
            onChange={e=>{
                if(maxLength!==undefined){
                    setTempValue(e.target.value.length>maxLength?e.target.value.slice(0,maxLength):e.target.value);
                    onChange(e.target.value.length>maxLength?e.target.value.slice(0,maxLength):e.target.value)
                }else{
                    setTempValue(e.target.value);
                    onChange(e.target.value);
                }
            }}
            onBlur={()=>{

            }}
            maxLength={maxLength}
            placeholder={hint}/>
        {
            (mtype === 'email' || mtype==='tel') && invalid?<div style={{color:'red',fontSize:12}}>{invalidString}</div>:''
        }
    </Container>
}
export default Component;
