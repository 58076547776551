import React,{useState,useEffect} from 'react';
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

import {Center} from './../../components/style'

////////////////////////////////////////////////////////////////////////////////

const Content = styled.div`
    flex: 1;
`;

////////////////////////////////////////////////////////////////////////////////

const Component = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    return <Center vertical>
        timeslot
    </Center>
}
export default Component;
