import React,{useState,useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {setConfig,setLanguage} from './../data/actions'
import {HashRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom'

import Welcome from './welcome'
import WelcomeSkip from './welcomeskip'
import Services from './services'
import Branches from './branches'
// import Departments from './departments'
import Timeslots from './timeslots'
import Forms from './forms'
import Appointment from './appointment'
// import Queue from './queue'
// import Feedback from './feedback'
// import Search from './search'
import Loading from './loading'

function Component(){
    const dispatch = useDispatch()
    const [loaded,setLoaded] = useState(false)

    useEffect(function(){
        async function readConfig(){
        //const readConfig = async () => {
            const response = await fetch('/config.json')
            const config = await response.json()
            dispatch(setConfig(config))
            setLoaded(true)
        }
        readConfig();
    },[])

    function MySwitch(){
        let query = new URLSearchParams(useLocation().search);
        useEffect(function(){
            dispatch(setLanguage(query.get('lang') === 'vi'? 'vi':'en'))
        },[query])

        return <>
        <Switch>
            <Route path="/" exact component={Welcome} />
            <Route path="/personal" exact render={function(props){return <WelcomeSkip {...props} type={'personal'}/>}} />
            <Route path="/corporate" exact render={function(props){<WelcomeSkip {...props} type={'corporate'}/>}} />

            <Route path="/services"         exact component={Services}/>
            <Route path="/branches"         exact component={Branches}/>
            <Route path="/timeslots"        exact component={Timeslots}/>
            <Route path="/forms"            exact component={Forms}/>
            <Route path="/appointment/"     exact component={Appointment}/>
            {/*<Route path="/departments"      exact component={Departments}/> */}
            {/*<Route path="/appointment/:id"  exact component={Appointment}/> */}
            {/*<Route path="/queue"            exact component={Queue}/> */}
            {/*<Route path="/feedback"         exact component={Feedback}/> */}
            {/*<Route path="/search"           exact component={Search}/> */}
            <Redirect to="/" />
        </Switch>

        </>
    }


    return loaded?
        <Router>
            <MySwitch />
        </Router> :
        <Loading/>

}
export default Component;
