import React from 'react';
import Pages from './pages'

import {createStore} from 'redux';
import {Provider} from 'react-redux';
import rootReducer from './data/reducers';

function App() {
    const store = createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    return <Provider store={store}>
        <Pages />
    </Provider>
}
export default App;
